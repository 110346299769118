import { Component, OnInit } from '@angular/core';
import * as Rellax from 'rellax';

import { environment } from '../../environments/environment.prod';
import * as mapboxgl from 'mapbox-gl';


// map
import { ViewChild, ElementRef } from '@angular/core';
import { NavigationControl, Map } from 'mapbox-gl';
import { MapService } from '../services/map/map.service';
import { Observable, of, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ScatterplotLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
   @ViewChild('mapEl', {static: true})
    mapEl: ElementRef<HTMLDivElement>;
    private map: Map;

    // curdate : Date = new Date();
    curdate = new Date().getFullYear();

  // map: mapboxgl.Map;
  style = 'mapbox://styles/firebuggirl/cj94md3tt098k2ssa3kemjcn6';
  zoom: number = 14;
  lat: -116.495811;
  lng: 33.352410;
  // token: string = mapboxgl.accessToken;
 
  // lat: number = 44.445248;
  // lng: number = 26.099672;
  styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    data : Date = new Date();
    focus;
    focus1;

    constructor(private mapSrv: MapService) {}

    ngOnInit() {
      var rellaxHeader = new Rellax('.rellax-header');

        var body = document.getElementsByTagName('body')[0];
        body.classList.add('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');

        // mapboxgl.accessToken = environment.mapbox.accessToken;
        // this.map = new mapboxgl.Map({
        
        // accessToken: environment.mapbox.accessToken,
        // container: 'map',
        // style: this.style,
        // zoom: 7.51,
        // center: [this.lat, this.lng]
        // });
        // // var layerList = document.getElementById('menu');
        // // var inputs = layerList.getElementsByTagName('input');
        //  // Add map controls
        // this.map.addControl(new mapboxgl.NavigationControl());
    }

    ngAfterViewInit(): void {
      // this.mapSrv.mapData$
      //   .pipe(
      //     switchMap(d => combineLatest(of(d), this.mapSrv.map)),
      //     map(([data, glMap]) => {
      //       return this.setLayers(glMap, data)
      //     })
      //   )
      //   .subscribe()
  
      // this.mapSrv.getData(1)
      //   .subscribe(d => this.mapSrv.mapDataSub.next(d))
  
      this.map = new Map({
        // accessToken: environment.mapbox.accessToken,
        container: this.mapEl.nativeElement,
        // style: 'mapbox://styles/mapbox/dark-v9',
        style: 'mapbox://styles/firebuggirl/cj94md3tt098k2ssa3kemjcn6',
        // style: this.style,
        // center: { lng: -102.380979, lat: 35.877742 },
        center: { lng: -116.495811, lat: 33.352410 },
        //center: [this.lat, this.lng],
        // zoom: 4,
        // zoom: this.zoom,
        zoom: 7.51
        // pitch: 20,
        // attributionControl: false
      });

      //  this.map = new mapboxgl.Map({
        
      //   accessToken: environment.mapbox.accessToken,
      //   container: 'map',
      //   style: this.style,
      //   zoom: 7.51,
      //   center: [this.lat, this.lng]
      //   });
      //   // var layerList = document.getElementById('menu');
      //   // var inputs = layerList.getElementsByTagName('input');
      //    // Add map controls
      //   this.map.addControl(new mapboxgl.NavigationControl());
  
      this.map.addControl(
        new NavigationControl({
          showZoom: true,
          showCompass: true,
          visualizePitch: true,
        }),
        'top-right'
      );
  
      this.mapSrv.map.next(this.map);
  
      this.map.on('load', () => {
        console.log('map loaded');
        this.mapSrv.map.complete();
      });
    }
  
    // setLayers(m: Map, data: any): Observable<Map> {
    //   const layer = m.getLayer('scatter')
    //   if (!!layer) {
    //     m.removeLayer('scatter')
    //   }
    //   console.log('setting layers')
    //   const scatter = new MapboxLayer({
    //     id: 'scatter',
    //     type: ScatterplotLayer,
    //     data,
    //     source: 'scatter',
    //     opacity: 0.8,
    //     filled: true,
    //     radiusMinPixels: 2,
    //     radiusMaxPixels: 5,
    //     getPosition: d => [d.longitude, d.latitude],
    //     getFillColor: d =>
    //       d.n_killed > 0 ? [200, 0, 40, 150] : [255, 140, 0, 100],
    //     pickable: true,
    //     onHover: ({ object, x, y }) => {
    //       if (!!object) {
    //         console.log(object, x, y);
    //       }
    //     }
    //   });
    //   m.addLayer(scatter);
    //   return of(m);
    // }
  
    loadData() {
      this.mapSrv.getData(2)
        .subscribe(d => this.mapSrv.mapDataSub.next(d));
    }

    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');


        this.mapSrv.map.subscribe(glMap => {
          glMap.removeLayer('scatter');
        });
    }


      
     
    
    

}
